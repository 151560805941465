import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BaseService } from 'projects/ProjetoBaseAngular/domain/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class RelatorioService extends BaseService {
  
  constructor(
    injector: Injector
    ) {
    super(injector);
  }

  public getController(): string {
    return 'Relatorio';
  }

  getRepasse(filterDto): Observable<any> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetRepasse`, {
        headers: this.getAuthHeaderJson().headers,
        params: filterDto
      })
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getRecebimento(filterDto): Observable<any> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetRecebimento`, {
        headers: this.getAuthHeaderJson().headers,
        params: filterDto
      })
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getDemonstrativo(filterDto): Observable<any> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetDemonstrativo`, {
        headers: this.getAuthHeaderJson().headers,
        params: filterDto
      })
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getTabela(filterDto): Observable<any> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetTabela`, {
        headers: this.getAuthHeaderJson().headers,
        params: filterDto
      })
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getPacientesPeriodo(filterDto): Observable<any> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetPacientesPeriodo`, {
        headers: this.getAuthHeaderJson().headers,
        params: filterDto
      })
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getAtendimentosPeriodo(filterDto): Observable<any> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetAtendimentosPeriodo`, {
        headers: this.getAuthHeaderJson().headers,
        params: filterDto
      })
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }

  getSolicitacoesByPeriodo(filterDto): Observable<any> {
    return this
      .httpClient
      .get(`${this.getApiUrl()}${this.getController()}/GetSolicitacoesByPeriodo`, {
        headers: this.getAuthHeaderJson().headers,
        params: filterDto
      })
      .pipe(
        map(this.extractData),
        catchError(this.serviceError)
      );
  }
}