import { Component, OnInit, ViewChild, Injector } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ModalDirective } from "ngx-bootstrap/modal";
import { TypesService } from "projects/admin/src/domain/services";
import { TenantModel } from "projects/ProjetoBaseAngular/domain/models";
import { SelectDto } from "projects/ProjetoBaseAngular/domain/models/select-dto";
import { TenantService } from "projects/ProjetoBaseAngular/domain/services";
import { PacienteTermoModel, PacienteModel } from "../../../domain/models";
import { PacienteTermoService } from "../../../domain/services/paciente-termo.service";
import { BaseComponent } from "projects/ProjetoBaseAngular/app/base.component";

@Component({
  selector: 'app-paciente-termo-detalhes-modal',
  templateUrl: './paciente-termo-detalhes-modal.component.html'
})
export class PacienteTermoDetalhesModalComponent extends BaseComponent implements OnInit {
  @ViewChild('modal') modal: ModalDirective;

  termoPacienteId: string = null;
  termoPaciente: PacienteTermoModel = null;
  tenant: TenantModel = null;

  constructor(
    injector: Injector,
    readonly service: PacienteTermoService,
    readonly tenantService: TenantService,
    readonly sanitizer: DomSanitizer,
    readonly typesService: TypesService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  async show(termoPacienteId: string) {
    this.termoPacienteId = termoPacienteId;
    this.termoPaciente = await this.service.getById(termoPacienteId).toPromise();
    this.tenant = await this.tenantService.getCurrentTenant().toPromise();

    this.modal.show();
  }  

  close() {
    this.modal.hide();
  }
  
}