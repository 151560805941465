<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}">
  <div class="modal-dialog modal-lg">
    <div class="card modal-content">
      <div class="header">
        <h2>Filtro - Chats</h2>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <mat-form-field>
              <mat-label>Tipo de Arquivo</mat-label>
              <mat-select [(ngModel)]="fileType">
                <mat-option value=-1>Todos</mat-option>
                <mat-option *ngFor="let item of fileTypes" [value]="item.value">
                  {{item.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>          
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-form-field>
              <mat-label>Descrição</mat-label>
              <input matInput [(ngModel)]="fileDescription" type="text" />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <button class="btn btn-outline-primary" type="button" (click)="anexarItem()">
              <i class='material-icons'>cloud_upload</i>
              <span> Enviar um arquivo </span>
            </button>
            <input id="inputUpfile" #fileUpload type="file" style="display: none;" (change)="prepareFile($event)" />
          </div>
        </div>
      </div>
      <div class="footer">
        <button mat-flat-button type="submit" (click)="enviarFile()" color="primary" class="button-row">
          <span> Enviar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close('cancelado')" class="button-row">
          <span>Fechar</span>
        </button>
      </div>
    </div>
  </div>
</div>



