import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { PacienteModel, PacienteTermoModel } from '../../domain/models';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TermoService } from '../../../atendimento/domain/services';
import { PacienteService } from '../../domain/services';
import { CidadeService, EstadoService, TenantService } from 'projects/ProjetoBaseAngular/domain/services';
import { PrintService } from 'projects/ProjetoBaseAngular/domain/services/print.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TypesService } from 'projects/admin/src/domain/services';
import { PacienteTermoService } from '../../domain/services/paciente-termo.service';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import * as moment from 'moment';
import { TermoControleType, TermoStatusType } from 'projects/admin/src/domain/types';
import { WhatsChatAtendenteService } from '../../../atendimento-online/domain/services/whatschat-atendente.service';

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfMake from 'html-to-pdfmake';
import vfs_fonts from 'pdfmake/build/vfs_fonts';
import { TenantModel } from 'projects/ProjetoBaseAngular/domain/models';
import { WhatsChatService } from '../../../atendimento-online/domain/services/whatschat.service';
import { WhatsChatStatusType } from '../../../atendimento-online/domain/types';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

// Adicione as fontes personalizadas
arial:

pdfMake.fonts = {
  // Arial: {
  //   normal: 'Arial.ttf',
  //   bold: 'Arial_Bold.ttf',
  //   italics: 'Arial_Bold_Italic.ttf', 
  //   bolditalics: 'Arial_Italic.ttf' 
  // },
  Arial: {
    normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
    italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
  },
};

@Component({
  selector: 'app-paciente-termo-modal',
  templateUrl: './paciente-termo-modal.component.html'
})
export class PacienteTermoModalComponent extends BaseComponent implements OnInit {
  @ViewChild('modal') modal: ModalDirective;

  listaTermos: SelectDto[];
  listaTermoPaciente: PacienteTermoModel[];

  pacienteId: string = null;
  paciente: PacienteModel;
  celularPaciente: string = '';
  tenant: TenantModel = null;

  termoSelecionadoId: string = null;

  selectColumns = [
    { data: 'nome' }
  ];

  dtOptions: DataTables.Settings = null;

  constructor(
    injector: Injector,
    readonly service: PacienteTermoService,
    readonly termoService: TermoService,
    readonly pacienteService: PacienteService,
    readonly tenantService: TenantService,
    readonly printService: PrintService,
    readonly sanitizer: DomSanitizer,
    readonly whatsChatService: WhatsChatService,
    readonly whatsChatAtendenteService: WhatsChatAtendenteService,
    readonly cidadeService: CidadeService,
    readonly estadoService: EstadoService,
    readonly typesService: TypesService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  async show(pacienteId: string) {
    this.pacienteId = pacienteId;
    this.paciente = await this.pacienteService.getById(pacienteId).toPromise();
    this.celularPaciente = this.commonService.celularToIdWhats(this.paciente.pessoa.celular);
    this.listaTermos = await this.termoService.getSelectList().toPromise();
    this.listaTermoPaciente = await this.service.getByPacienteId(pacienteId).toPromise();
    this.tenant = await this.tenantService.getCurrentTenant().toPromise();
    this.orderPacienteTermos();

    this.modal.show();
  }

  close() {
    this.modal.hide();
  }

  //#region Envio de Termos  
  async enviarTermo(termoId: string) {
    let confirmacaoEnvio = null;
    this.termoSelecionadoId = termoId;
    let termoSelecionado = this.listaTermos.find(x => x.value === termoId);
    let termoDtoExistente = this.listaTermoPaciente.find(x => x.nome === termoSelecionado.text);
    if (termoDtoExistente && termoDtoExistente.aceito === true) {
      confirmacaoEnvio = await this.commonService.mensagemConfirmacao(`O paciente: ${this.paciente.pessoa.nomeFantasia} ja possui um Termo igual na data: ${this.commonService.formatDate(termoDtoExistente.dataResposta)}`, 'Deseja enviar um Termo novamente?', 'info');
      if (!confirmacaoEnvio) {
        return;
      }
    }

    let chatExistente = await this.service.getChatAtivoByNumeroWhats(this.celularPaciente).toPromise();

    if (chatExistente.tipoChat !== null && chatExistente.tipoChat !== undefined) {
      await this.commonService.mensagem(`O paciente: ${this.paciente.pessoa.nomeFantasia} ja possui um Chat Ativo do tipo: ${this.typesService.getWhatsChatTypeNome(chatExistente.tipoChat)}`, 'Cancele ou Finalize o Chat com o Paciente antes de prosseguir com o envio do termo', 'error');
      return;
    }

    if (!!!confirmacaoEnvio) {
      confirmacaoEnvio = await this.commonService.mensagemConfirmacao('Deseja enviar o termo para o paciente?', '', 'info');
    }
    if (confirmacaoEnvio) {
      let conteudoTermo = await this.tratarTextoTermo(termoId);

      if (!conteudoTermo) {
        await this.commonService.mensagem('Erro ao enviar termo', 'Existem campos não preenchidos no termo', 'error');
        return;
      }

      let termoPdfBase64 = await this.gerarPDFBase64(conteudoTermo);

      try {
        this.commonService.spinnerOpen('Enviando termo...');
        let termoSelecionado = await this.termoService.getById(this.termoSelecionadoId).toPromise();

        const pacienteTermoModel = new PacienteTermoModel();

        pacienteTermoModel.pacienteId = this.pacienteId;
        pacienteTermoModel.nome = termoSelecionado.nome;
        pacienteTermoModel.textoTermo = conteudoTermo;
        pacienteTermoModel.dataEnvio = new Date();
        pacienteTermoModel.status = TermoStatusType.Pendente;
        pacienteTermoModel.aceito = false;
        pacienteTermoModel.tipoControle = TermoControleType.Bot;

        //termoPdfBase64 = termoPdfBase64.replace(/^data:application\/pdf;filename=generated.pdf;base64,/, 'data:application/octet-stream;base64,');
        termoPdfBase64 = 'data:application/octet-stream;base64,' + termoPdfBase64;

        let pacienteTermo = await this.service.create(pacienteTermoModel).toPromise();
        this.listaTermoPaciente.push(pacienteTermoModel);
        this.orderPacienteTermos();

        let result = await this.whatsChatService.criarChatAceiteTermo(pacienteTermo.id, this.celularPaciente, termoPdfBase64, pacienteTermo.textoTermo, 'Termo_PDF').toPromise();

        if (result) {
          //console.log('Termo enviado com sucesso: ' + result);
          this.commonService.spinnerClose();
          this.commonService.mensagem('Termo enviado com sucesso', '', 'success');
        }
      } catch (error) {
        //console.error('Erro ao enviar termo:', error);
        this.commonService.spinnerClose();
        this.commonService.mensagem('Erro ao enviar termo', error, 'error');
      }
    }
  }
  //#endregion

  //#region Exclusão de Termos
  async excluirPacienteTermo(pacienteTermoId: string) {
    let pacienteTermo = this.listaTermoPaciente.find(x => x.id === pacienteTermoId);
    let confirmacao = await this.commonService.mensagemConfirmacao(`Deseja excluir o Termo: ${pacienteTermo.nome} do paciente: ${this.paciente.pessoa.nomeFantasia}?`, '', 'warning');
    if (confirmacao) {
      await this.service.delete(pacienteTermoId).toPromise();
      this.listaTermoPaciente = this.listaTermoPaciente.filter(x => x.id !== pacienteTermoId);
      this.commonService.mensagem('Termo excluído com sucesso', '', 'success');
    }
  }
  //#endregion

  //#region metodos gerais
  async tratarTextoTermo(id: string): Promise<string> {
    const termo = await this.termoService.getById(id).toPromise();
    let data = new Date();
    let cidadeModel = await this.cidadeService.getById(this.paciente.pessoa.cidadeId).toPromise();
    let estadoModel = await this.estadoService.getById(this.paciente.pessoa.estadoId).toPromise();

    const substituicoes = {
      pacienteNome: this.paciente.pessoa.nomeFantasia,
      pacienteCpf: this.paciente.pessoa.cnpjCpf,
      pacienteRuaAvenida: this.paciente.pessoa.ruaAvenida,
      pacienteNumero: this.paciente.pessoa.numero,
      pacienteBairro: this.paciente.pessoa.bairro,
      pacienteCidade: cidadeModel.nome,
      pacienteBairroCidade: this.paciente.pessoa.bairro,
      pacienteEstadoUF: estadoModel.uf,
      pacienteEndereco: `${this.paciente.pessoa.ruaAvenida}${this.paciente.pessoa.numero ? ', ' + this.paciente.pessoa.numero : ''}, ${this.paciente.pessoa.bairro}, ${cidadeModel.nome}/${estadoModel.uf}`,
      pacienteCelular: this.paciente.pessoa.celular,
      data: moment(data).format('DD/MM/YYYY'),
      empresaNome: this.tenant.nomeFantasia,
      empresaEndereco: `${this.tenant.ruaAvenida}${this.tenant.numero ? ', ' + this.tenant.numero : ''}, ${this.tenant.bairro}, ${this.tenant.cidadeNome}/${this.tenant.estadoUf}`,
      empresaCelular: this.tenant.celular,
    };
  
    // Mapeamento dos campos para nomes mais legíveis
    const camposLegiveis = {
      pacienteNome: 'Paciente Nome',
      pacienteCpf: 'Paciente CPF',
      pacienteRuaAvenida: 'Paciente Rua/Avenida',
      pacienteNumero: 'Paciente Número',
      pacienteBairro: 'Paciente Bairro',
      pacienteCidade: 'Paciente Cidade',
      pacienteBairroCidade: 'Paciente Bairro da Cidade',
      pacienteEstadoUF: 'Paciente Estado (UF)',
      pacienteEndereco: 'Paciente Endereço',
      pacienteCelular: 'Paciente Celular',
      data: 'Data',
      empresaNome: 'Empresa Nome',
      empresaEndereco: 'Empresa Endereço',
      empresaCelular: 'Empresa Celular',
    };
  
    // Listar os campos que não foram preenchidos
    const camposNaoPreenchidos = Object.entries(substituicoes)
      .filter(([_, valor]) => !valor || valor.trim() === '')
      .map(([chave, _]) => camposLegiveis[chave]);
  
    // Mostra quais campos não foram preenchidos
    if (camposNaoPreenchidos.length > 0) {
      await this.commonService.mensagem('Existem Campos não preenchidos: ', camposNaoPreenchidos.join(', '), 'error');
      //console.log('Campos não preenchidos:', camposNaoPreenchidos.join(', '));
      return null;
    }
  
    // Substitui os valores no texto usando a lista de substituições
    let conteudoTermo = termo.texto;
    for (const [chave, valor] of Object.entries(substituicoes)) {
      conteudoTermo = conteudoTermo.replace(new RegExp(`{{${chave}}}`, 'g'), valor);
    }
  
    return conteudoTermo;
  }

  gerarPDFBase64(textoTermo: string): Promise<string> {
    return new Promise((resolve, reject) => {
      // Cria um elemento div temporário para converter o HTML
      const div = document.createElement('div');
      div.innerHTML = textoTermo;

      // Converte o HTML para o formato que pdfmake entende
      const pdfContent = htmlToPdfMake(div.innerHTML);

      const docDefinition = {
        content: pdfContent,
        defaultStyle: {
          font: 'Arial'
        }
      };

      const pdfDocGenerator = pdfMake.createPdf(docDefinition);

      // Para teste Salva uma cópia do PDF na máquina
      // pdfDocGenerator.download('conteudo.pdf');

      // Gera o PDF em base64
      pdfDocGenerator.getBase64((data) => {
        resolve(data);
      });
    });
  }

  orderPacienteTermos() {
    this.listaTermoPaciente = this.listaTermoPaciente.sort((a, b) => {
      return new Date(b.dataEnvio).getTime() - new Date(a.dataEnvio).getTime();
    });
  }
  //#endregion
}
