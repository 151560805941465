import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { TypesService } from 'projects/admin/src/domain/services';
import { SolicitacaoOnlineFiltroDto, SolicitacaoOnlineModel, WhatsChatFiltroDto, WhatsChatModel, WhatsChatMsgModel, WhatsMsgAtendenteDto } from '../domain/models';
import { WhatsChatService } from '../domain/services/whatschat.service';
import { BotAtendimentoType, ConfPresencaFilaStatusType, SolicitacaoOnlineStatusType, WhatsChatMsgMidiaType, WhatsChatMsgType, WhatsChatStatusType, WhatsChatType } from '../domain/types';
import { WhatsChatMsgService } from '../domain/services/whatschat-msg.service';
import * as moment from 'moment';
import { SolicitacaoOnlineService } from '../domain/services';
import { DocFiltroModalComponent } from './docfiltro-modal/docfiltro-modal.component';
import { EmpresaService } from '../../empresa/domain/servides';
import { TenantModel, UsuarioModel } from 'projects/ProjetoBaseAngular/domain/models';
import { ChatFiltroModalComponent } from './chatfiltro-modal/chatfiltro-modal.component';
import { WhatsChatAtendenteService } from '../domain/services/whatschat-atendente.service';
import Swal from 'sweetalert2';
import { UsuarioService } from 'projects/ProjetoBaseAngular/domain/services';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { PacienteSelectModalComponent } from '../../paciente/paciente/paciente-select-modal/paciente-select-modal.component';
import { PacienteService } from '../../paciente/domain/services';
import { PacienteModel } from '../../paciente/domain/models';
import { BotDocModalComponent } from './bot-doc-modal/bot-doc-modal.component';
import { LocalStorageService } from 'projects/ProjetoBaseAngular/domain/services/local-storage.service';
import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from '@aspnet/signalr';
import { ConfirmacaoPresencaFilaService } from '../domain/services/confirmacao-presenca-fila.service';
import { ImgViewModalComponent } from './imgview-modal/imgview-modal.component';
import { WhatsFileUploadModalComponent } from './whats-file-upload-modal/whats-file-upload-modal.component';
import { ImagemApresentacaoModalComponent } from 'projects/ProjetoBaseAngular/app/admin/galeria/imagens/imagem-apresentacao-modal/imagem-apresentacao-modal.component';

@Component({
  selector: 'app-whatsapp-chat',
  templateUrl: './whatsapp-chat.component.html',
  styleUrls: ['./whatsapp-chat.component.css']
})
export class WhatsAppChatComponent extends BaseComponent implements OnInit {
  @ViewChild('docfiltroModal') docfiltroModal: DocFiltroModalComponent;
  @ViewChild('chatfiltroModal') chatfiltroModal: ChatFiltroModalComponent;
  @ViewChild('pacienteSelect') pacienteSelect: PacienteSelectModalComponent;
  @ViewChild('botDocModal') botDocModal: BotDocModalComponent;
  @ViewChild('imgViewModal') imgViewModal: ImgViewModalComponent;
  @ViewChild('imgApresentacaoModal') imgApresentacaoModal: ImagemApresentacaoModalComponent;
  @ViewChild('whatsFileUploadModal') whatsFileUploadModal: WhatsFileUploadModalComponent;

  whatsChatType: typeof WhatsChatType = WhatsChatType;
  whatsChatMsgType: typeof WhatsChatMsgType = WhatsChatMsgType;
  whatsChatMsgMidiaType: typeof WhatsChatMsgMidiaType = WhatsChatMsgMidiaType;
  botAtendimentoType: typeof BotAtendimentoType = BotAtendimentoType;

  chatMessage: string;
  messageRows: number = 1;

  selectedChat: WhatsChatModel = null;
  selectedDoc: SolicitacaoOnlineModel = null;

  dtoChatFilter: WhatsChatFiltroDto;
  dtoDocFilter: SolicitacaoOnlineFiltroDto;

  listaChat: WhatsChatModel[] = [];
  listaChatAtendente: WhatsChatModel[] = [];
  listaChatBot: WhatsChatModel[] = [];

  qtdBolotinhasAtendente: number = 0;
  qtdBolotinhasBot: number = 0;

  listaMsg: WhatsChatMsgModel[] = [];

  listaSolicitacao: SolicitacaoOnlineModel[] = [];

  listaUsuario: SelectDto[] = [];

  usuarioModel: UsuarioModel;
  tenantModel: TenantModel;

  // para conexão com o Hub de Chat no back-end
  private _client: HubConnection;

  constructor(
    injector: Injector,
    readonly service: WhatsChatService,
    readonly atendenteService: WhatsChatAtendenteService,
    readonly msgService: WhatsChatMsgService,
    //readonly msgMediaService: WhatsChatMsgMediaService,
    readonly solicitacaoService: SolicitacaoOnlineService,
    readonly empresaService: EmpresaService,
    readonly usuarioService: UsuarioService,
    readonly pacienteService: PacienteService,
    readonly storageService: LocalStorageService,
    readonly confirmacaoFilaService: ConfirmacaoPresencaFilaService,
    readonly typesService: TypesService
  ) {
    super(injector);
    this._client = new HubConnectionBuilder()
      .configureLogging(LogLevel.Error)
      .withUrl(service.getHubUrl(), {
        // skipNegotiation: false,
        // transport: HttpTransportType.WebSockets
      })
      .build();

    this._client.start()
      // .then(() => console.log('Connection Started'))
      .then(() => {
        this._client.send("ConectarGrupo", `${this.accountService.getUser().id}`)
      })
      .catch(err => console.log('Erro ao iniciar conexão: ' + err));
  }

  ngOnInit() {
    super.ngOnInit();

    this._client.on("hubFinalizarChat", (chatId) => {
      this.hubFinalizarChat(chatId);
    });
    this._client.on("hubCancelarChat", (chatId) => {
      this.hubCancelarChat(chatId);
    });
    this._client.on("hubTransferirChat", (chatId, usuarioId) => {
      this.hubTransferirChat(chatId, usuarioId);
    });
    this._client.on("hubCriarChat", (chatModel) => {
      this.hubCriarChat(chatModel);
    });
    this._client.on("hubEnviarMsg", (chatId, msg) => {
      this.hubEnviarMsg(chatId, msg);
    });
    this._client.on("hubWebhookAction", (chat) => {
      this.hubWebHookAction(chat);
    });
    this._client.on("hubCriarSolicitacao", (solicitacao) => {
      this.hubCriarSolicitacao(solicitacao);
    });
    this._client.on("hubEditSolicitacao", (solicitacao) => {
      this.hubEditSolicitacao(solicitacao);
    });

    this.usuarioModel = this.accountService.getUser();

    this.dtoChatFilter = new WhatsChatFiltroDto();
    this.dtoChatFilter.status = WhatsChatStatusType.Ativo;

    this.dtoDocFilter = new SolicitacaoOnlineFiltroDto();
    this.dtoDocFilter.status = SolicitacaoOnlineStatusType.Pendente_EmAtendimento;

    this.usuarioService.getSelectList().subscribe(x => {
      this.listaUsuario = x;
      this.filtrarChat();
      this.filtrarSolicitacao();
    });

    this.empresaService.getEmpresa().subscribe(x => this.tenantModel = x);
  }

  async selectChat(id: string): Promise<void> {
    const newSelectedChat = this.listaChat.find(x => x.id === id);
    if (newSelectedChat) {

      //! alterado devido a Lista Tarefas MrDoctor Ver 1.0 Setembro 2024, 1-B
      if (this.selectedChat) {
        if (this.selectedChat.id !== newSelectedChat.id) {
          this.salvarRascunhoByChatId(this.selectedChat.id);
          this.limparChatInput();
        }
      }

      this.unselectChat();
      newSelectedChat.selected = "active";

      this.selectedChat = newSelectedChat;

      if (this.selectedChat.textoRascunho) {
        this.chatMessage = this.selectedChat.textoRascunho;
      }

      await this.getMsgChat(id);

      if (this.selectedChat.qtdMsgNaoVista > 0) {
        this.selectedChat.qtdMsgNaoVista = 0;
        await this.atendenteService.atualizarMsgVista(this.selectedChat.id).toPromise();
      }
    }
  }

  salvarRascunhoByChatId(chatId: string) {
    if (this.chatMessage) {
      this.listaChat.find(x => x.id === chatId).textoRascunho = this.chatMessage;
    }
  }

  unselectChat() {
    if (this.selectedChat) {
      this.selectedChat.selected = "";
    }
    this.selectedChat = null;
    this.listaMsg = [];
  }

  unselectDoc() {
    if (this.selectedDoc) {
      this.selectedDoc.selected = "";
    }

    this.selectedDoc = null;
  }

  async selectDoc(id: string): Promise<void> {
    const newSelectedDoc = this.listaSolicitacao.find(x => x.id === id);
    if (newSelectedDoc) {
      this.unselectDoc();

      newSelectedDoc.selected = "active";
      this.selectedDoc = newSelectedDoc;
    }
  }

  async filtrarChat(): Promise<void> {
    this.limparChatInput();

    this.unselectChat();
    let dto = { ...this.dtoChatFilter };
    if (Number(dto.status) === -1) {
      dto.status = null;
    }

    if (Number(dto.tipoChat) === -1) {
      dto.tipoChat = null;
    }

    this.listaChat = await this.service.getByFiltro(dto).toPromise();
    this.listaChat = this.commonService.orderBy(this.listaChat, 'dataHoraUltimaMsgRecebida', false);
    this.listaChat.forEach(item => {
      if (item.tipoChat === WhatsChatType.Atendente) {
        if (item.usuarioId) {
          item.nomeUsuario = this.listaUsuario.find(x => x.value === item.usuarioId)?.text;
        } else {
          item.nomeUsuario = "";
        }
      } else {
        item.nomeUsuario = "Bot";
      }
    });
    this.updateListasChat();
  }

  updateListasChat() {
    this.listaChatAtendente = this.listaChat.filter(x => x.tipoChat === WhatsChatType.Atendente);
    this.listaChatBot = this.listaChat.filter(x => x.tipoChat === WhatsChatType.Bot);
    this.calcularBolotinhas();
  }

  hubWebHookAction(model: WhatsChatModel) {
    if (this.dtoChatFilter.status === WhatsChatStatusType.Cancelado ||
      this.dtoChatFilter.status === WhatsChatStatusType.Finalizado) {
      return;
    }

    const itemLista = this.listaChat.find(x => x.id === model.id)
    if (itemLista) {
      itemLista.qtdMsgNaoVista = model.qtdMsgNaoVista;
      itemLista.ultimaMsgRecebida = model.ultimaMsgRecebida;
      itemLista.status = model.status;

      const index = this.listaChat.indexOf(itemLista);
      if (index > -1) {
        this.listaChat.splice(index, 1);
        this.listaChat.unshift(itemLista);
      }
    } else {
      if (model.tipoChat === WhatsChatType.Atendente) {
        if (model.usuarioId) {
          model.nomeUsuario = this.listaUsuario.find(x => x.value === model.usuarioId)?.text;
        } else {
          model.nomeUsuario = "";
        }
      } else {
        model.nomeUsuario = "Bot";
      }
      this.listaChat.unshift(model);
    }
    this.updateListasChat();
  }

  async filtrarSolicitacao() {
    this.unselectDoc();
    let dto = { ...this.dtoDocFilter };

    if (Number(dto.status) === -100) {
      dto.status = null;
    }

    if (Number(dto.tipo) === -1 || dto.tipo === BotAtendimentoType.NaoInformado) {
      dto.tipo = null;
    }

    this.listaSolicitacao = await this.solicitacaoService.getByFiltro(dto).toPromise();
    this.listaSolicitacao.forEach(item => {
      if (item.usuarioId) {
        item.nomeUsuario = this.listaUsuario.find(x => x.value === item.usuarioId)?.text;
      } else {
        item.nomeUsuario = "Bot";
      }
    });
  }

  hubCriarSolicitacao(model: SolicitacaoOnlineModel) {
    if (this.dtoDocFilter.status === SolicitacaoOnlineStatusType.Finalizado ||
      this.dtoDocFilter.status === SolicitacaoOnlineStatusType.Cancelado) {
      return;
    }
    if (model.usuarioId) {
      model.nomeUsuario = this.listaUsuario.find(x => x.value === model.usuarioId)?.text;
    } else {
      model.nomeUsuario = "Bot";
    }
    this.listaSolicitacao.unshift(model);
  }

  async getMsgChat(id: string) {
    this.listaMsg = await this.msgService.getByChatId(id).toPromise();

    this.listaMsg.forEach(item => {
      if (item.media) {
        item.mensagem = item.media.caption;
      }

      item.mensagem = this.commonService.formatWhatsToHtml(item.mensagem);
      item.mensagem += `<br><br><sup>${moment(item.dataHora).format('DD/MM/YYYY HH:mm')}</sup>`;
    });
  }

  async imgClick(itemId: string) {
    const model = this.listaMsg.find(x => x.id === itemId);
    if (model.media) {
      const dtoImg = await this.msgService.downloadImage(itemId).toPromise();
      if (dtoImg.success) {
        this.imgViewModal.show(dtoImg.data.data, model.media.height, model.media.width);
      }
    } else if (model.arquivoUrl) {
      this.imgApresentacaoModal.showImagem(model.arquivoUrl);
    }
  }

  showDocFiltro() {
    this.docfiltroModal.show(this.dtoDocFilter);
  }

  filtroDocResponse(dto: SolicitacaoOnlineFiltroDto) {
    if (dto['filtrar']) {
      this.dtoDocFilter = dto;
      this.filtrarSolicitacao();
    }
  }

  showChatFiltro() {
    this.chatfiltroModal.show(this.dtoChatFilter);
  }

  filtroChatResponse(dto: WhatsChatFiltroDto) {
    if (dto['filtrar']) {
      this.dtoChatFilter = dto;
      this.filtrarChat();
    }
  }

  async finalizarChat() {
    if (!this.selectedChat) {
      await this.commonService.mensagem("Chat não selecionado", "Selecione um Chat para executar esta operação.", "info");
      return;
    }
    let descricao = this.selectedChat.nomeContato ? this.selectedChat.nomeContato : this.selectedChat.numeroWhatsApp;
    let mensagem = `Confirmar finalização de Chat com ${descricao}?`;
    let result = await this.commonService.mensagemConfirmacao("Finalizar Chat", mensagem, "question");
    if (result) {
      const dto = new WhatsMsgAtendenteDto();
      dto.texto = `*${this.tenantModel.nomeFantasia}*\n_Atendimento Finalizado._`;
      let result = await this.atendenteService.finalizarChat(this.selectedChat.id, dto).toPromise();
      if (result) {
        this.selectedChat.status = WhatsChatStatusType.Finalizado;
      }
      await this.verificarFilaConfPres(this.selectedChat.numeroWhatsApp);
    }
  }

  hubFinalizarChat(chatId: string) {
    let chatModel = this.listaChat.find(x => x.id === chatId);
    if (chatModel) {
      chatModel.status = WhatsChatStatusType.Finalizado;
    }
    this.updateListasChat();
  }

  async cancelarChat() {
    if (!this.selectedChat) {
      await this.commonService.mensagem("Chat não selecionado", "Selecione um Chat para executar esta operação.", "info");
      return;
    }
    let descricao = this.selectedChat.nomeContato ? this.selectedChat.nomeContato : this.selectedChat.numeroWhatsApp;
    let mensagem = `Confirmar cancelamento de Chat com ${descricao}?`;
    let result = await this.commonService.mensagemConfirmacao("Cancelar Chat", mensagem, "question");
    if (result) {
      const dto = new WhatsMsgAtendenteDto();
      dto.texto = `*${this.tenantModel.nomeFantasia}*\n_Atendimento Cancelado._`;

      let result = await this.atendenteService.cancelarChat(this.selectedChat.id, dto).toPromise();
      if (result) {
        this.selectedChat.status = WhatsChatStatusType.Cancelado;
      }

      await this.verificarFilaConfPres(this.selectedChat.numeroWhatsApp);
    }
  }

  hubCancelarChat(chatId: string) {
    let chatModel = this.listaChat.find(x => x.id === chatId);
    if (chatModel) {
      chatModel.status = WhatsChatStatusType.Cancelado;
    }
    this.updateListasChat();
  }

  async verificarFilaConfPres(numeroWhats: string): Promise<void> {
    //limpar fila de msg vencida
    await this.service.validarFilaConfByNumeroWhats(numeroWhats).toPromise();

    //Verificar para envio de sequencia de msg em fila
    let listaPresFila = await this.confirmacaoFilaService.getByNumeroWhats(numeroWhats, ConfPresencaFilaStatusType.Pendente).toPromise();

    if (listaPresFila.length > 0) {
      let mensagem1 = `Paciente possuí ${listaPresFila.length} mensagen(s) de confirmação de presença enfileiradas e pendentes de envio.`;
      let mensagem2 = `Iniciar o envio da 1° da fila ?`;
      let result = await this.commonService.mensagemConfirmacao(mensagem1, mensagem2, "question");
      if (result) {
        await this.service.enviarConfirmacaoFila(numeroWhats).toPromise();
      }
    }
  }

  initChatPaciente() {
    this.pacienteSelect.showSelect();
  }

  async onPacienteResponse(pacienteId: string) {
    const paciente: PacienteModel = await this.pacienteService.getById(pacienteId).toPromise();

    if (paciente.idWhatsApp) {
      const dto = new WhatsMsgAtendenteDto();
      dto.idWhatsApp = paciente.idWhatsApp;
      dto.nomeContato = paciente.pessoa.nomeFantasia.substring(0, 30);
      dto.pacienteId = paciente.id;

      await this.iniciarChat(dto);
    }
  }

  async initChatNaoPaciente() {
    const resultFone = await Swal.fire({
      title: 'Informe o número do fone WhatsApp',
      html: `Não utilize o código (55 - Brasil). Pois será introduzido automaticamente.`,
      icon: 'question',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    });

    if (resultFone.isConfirmed) {
      let numFone: string = resultFone.value;
      numFone = this.commonService.celularToIdWhats(numFone);
      if (!numFone) {
        await this.commonService.mensagem("Atenção Número informado é inválido.", "Verifique se o número informado está correto.", "info");
        return;
      }

      const dto = new WhatsMsgAtendenteDto();
      dto.idWhatsApp = numFone;
      //Neste caso não tem o nome então usa o número
      dto.nomeContato = this.commonService.idWhatsToCelular(numFone);

      await this.iniciarChat(dto);
    }
  }

  async iniciarChat(dto: WhatsMsgAtendenteDto): Promise<boolean> {
    let listaFone = [dto.idWhatsApp];
    const result = await this.service.getUsersCheck(listaFone).toPromise();

    if (!result.success || !result.data.users[0].isInWhatsapp) {
      await this.commonService.mensagem("Este número não está no WhatsApp.", "Verifique se o número informado está correto.", "info");
      return false;
    }

    const modelAtivo = await this.atendenteService.getChatAtivoByFone(dto.idWhatsApp).toPromise();
    if (modelAtivo.id) {
      await this.commonService.mensagem("Já existe um Chat Ativo para este contato.", "Verifique na lista de Chats de Atendente ou Bot.", "info");
      return false;
    }

    dto.usuarioId = this.usuarioModel.id;
    dto.usuarioNome = this.usuarioModel.nome;
    dto.texto = `*${this.tenantModel.nomeFantasia}*\n*_Atendimento Digital._*\n\n`;
    dto.texto += `Atendente ${dto.usuarioNome}\n`;
    if (dto.nomeContato) {
      dto.texto += `Olá ${dto.nomeContato}`;
    }

    const model = await this.atendenteService.criarChatAtendente(dto).toPromise();
    model.nomeUsuario = dto.usuarioNome;

    this.listaChat.unshift(model);
    this.selectChat(model.id);
    this.updateListasChat();

    return true;
  }

  hubCriarChat(model: WhatsChatModel) {
    model.nomeUsuario = this.listaUsuario.find(x => x.value === model.usuarioId).text;
    this.listaChat.unshift(model);
    this.updateListasChat();
    this.selectChat(model.id);
  }

  async anexarItem() {
    if (! await this.isChatAtivo()) {
      return;
    } else {
      this.whatsFileUploadModal.show(this.selectedChat, this.usuarioModel, this.tenantModel);
    }
    //this.commonService.mensagem("Em desenvolvimento", "Esta funcionalidade será implementada na nova versão do Sistema.", "info");    
  }

  async enviarMensgem() {
    if (! await this.isChatAtivo()) {
      return;
    }

    if (this.selectedChat.usuarioId !== this.usuarioModel.id) {
      await this.commonService.mensagem("Atenção Chat em atendimento por outro usuário", "Transfira a responsabilidade para o seu usuário para poder enviar mensagens.", "info");
      return;
    }

    let result = await this.enviarMensgemInterno(this.chatMessage);

    if (result) {
      let element = document.getElementById('inputChatMessage');
      element.focus();
    }
  }

  async enviarMensgemInterno(texto: string): Promise<boolean> {

    const dto = new WhatsMsgAtendenteDto();
    dto.idWhatsApp = this.selectedChat.numeroWhatsApp;
    dto.usuarioId = this.usuarioModel.id;
    dto.usuarioNome = this.usuarioModel.nome;

    dto.texto = `*Atendente* ${dto.usuarioNome}\n`;
    dto.texto += texto;

    let result = await this.atendenteService.enviarMensagem(this.selectedChat.id, dto).toPromise();
    if (result) {
      this.limparChatInput();
      await this.getMsgChat(this.selectedChat.id);

      return true;
    }

    return false;
  }

  hubEnviarMsg(chatId: string, msg: string) {
    if (this.selectedChat.id === chatId) {
      this.getMsgChat(this.selectedChat.id);
    }
  }

  async tranferirChat() {
    if (! await this.isChatAtivo()) {
      return;
    }

    if (! await this.confirmarTransferencia()) {
      return;
    }
    await this.atendenteService.transferirChat(this.selectedChat.id, this.usuarioModel.id).toPromise();

    this.selectedChat.tipoChat = WhatsChatType.Atendente;
    this.selectedChat.usuarioId = this.usuarioModel.id;
    this.selectedChat.nomeUsuario = this.usuarioModel.nome;

    // this.listaChatBot = this.listaChatBot.filter(x => x.id !== this.selectedChat.id);
    // this.listaChatAtendente.unshift(this.selectedChat);
    this.updateListasChat();

    this.enviarMensgemInterno("Olá, seu atendimento foi transferido.\n A partir de agora estarei a disposição para lhe ajudar.");
  }

  hubTransferirChat(chatId: string, usuarioId: string) {
    let chatModel = this.listaChat.find(x => x.id === chatId);
    let usuario = this.listaUsuario.find(x => x.value === usuarioId);
    chatModel.usuarioId = usuarioId;
    chatModel.nomeUsuario = usuario.text;
  }

  async confirmarTransferencia(): Promise<boolean> {
    let result: boolean;

    if (this.selectedChat.tipoChat === WhatsChatType.Atendente) {
      if (this.selectedChat.usuarioId === this.usuarioModel.id) {
        await this.commonService.mensagem(`Chat já está sendo atendido por ${this.usuarioModel.nome}`, "Transferência não é necessária.", "info");
        return false;
      } else {
        let mensagem = `Transferir atendimento de Chat de usuário ${this.selectedChat.nomeUsuario} para ${this.usuarioModel.nome}?`;
        result = await this.commonService.mensagemConfirmacao(mensagem, "Confirmar transferência?", "question");
        if (!result) return false;
      }
    } else {
      result = await this.commonService.mensagemConfirmacao("Chat está em atendimento pelo Bot", `Confirmar transferência para o usuário ${this.usuarioModel.nome}?`, "question");
      if (!result) return false;
    }

    return true;
  }

  async isChatAtivo(): Promise<boolean> {
    if (!this.selectedChat) {
      await this.commonService.mensagem("Selecione o Chat", "Não há um Chat selecionado.", "warning");
      return false;
    }

    if (this.selectedChat.status !== WhatsChatStatusType.Ativo) {
      await this.commonService.mensagem("Chat não está Ativo", "Somente Chats Ativos aceitam esta operação.", "warning");
      return false;
    }

    return true;
  }

  async showDetalheDoc() {
    if (!this.selectedDoc) {
      await this.commonService.mensagem("Selecione um documento", "Para ver detalhes selecione um documento.", "info");
      return;
    }

    this.botDocModal.show(this.selectedDoc);
  }

  async finalizarDoc() {
    if (!await this.docPodeMudarStatus()) {
      return;
    }

    let mensagem = `Confirmar finalização de solicitação de ${this.selectedDoc.nomePaciente}?`;
    let result = await this.commonService.mensagemConfirmacao("Finalizar Solicitação", mensagem, "question");
    if (result) {
      this.selectedDoc.status = SolicitacaoOnlineStatusType.Finalizado;
      await this.solicitacaoService.edit(this.selectedDoc).toPromise();
    }
  }

  async cancelarDoc() {
    if (!await this.docPodeMudarStatus()) {
      return;
    }

    let mensagem = `Confirmar cancelamento de solicitação de ${this.selectedDoc.nomePaciente}?`;
    let result = await this.commonService.mensagemConfirmacao("Cancelar Solicitação", mensagem, "question");
    if (result) {
      this.selectedDoc.status = SolicitacaoOnlineStatusType.Cancelado;
      await this.solicitacaoService.edit(this.selectedDoc).toPromise();
    }
  }

  async iniciarAtendimentoDoc() {
    if (!await this.docPodeMudarStatus()) {
      return;
    }

    this.selectedDoc.status = SolicitacaoOnlineStatusType.EmAtendimento;
    this.selectedDoc.usuarioId = this.usuarioModel.id;
    this.selectedDoc.nomeUsuario = this.usuarioModel.nome;

    await this.solicitacaoService.edit(this.selectedDoc).toPromise();
  }

  hubEditSolicitacao(model: SolicitacaoOnlineModel) {
    let itemLista = this.listaSolicitacao.find(x => x.id === model.id);
    if (itemLista) {
      let usuario = this.listaUsuario.find(x => x.value === model.usuarioId);
      itemLista.status = model.status;
      itemLista.usuarioId = usuario.value;
      itemLista.nomeUsuario = usuario.text;
    }
  }

  async docPodeMudarStatus(): Promise<boolean> {
    if (!this.selectedDoc) {
      await this.commonService.mensagem("Selecione o Documento", "Não há um Documento selecionado.", "warning");
      return false;
    }

    if (this.selectedDoc.status === SolicitacaoOnlineStatusType.Finalizado
      || this.selectedDoc.status === SolicitacaoOnlineStatusType.Cancelado) {
      await this.commonService.mensagem("Documento já está Cancelado ou Finalizado", "Operação não será executada.", "warning");
      return false;
    }

    return true;
  }

  async iniciarChatDoc() {
    if (this.selectedDoc.numeroPaciente) {
      const dto = new WhatsMsgAtendenteDto();
      dto.idWhatsApp = this.selectedDoc.numeroPaciente;
      dto.nomeContato = this.selectedDoc.nomePaciente.substring(0, 30);
      dto.pacienteId = this.selectedDoc.pacienteId;

      await this.iniciarChat(dto);

      if (this.selectedDoc.status === SolicitacaoOnlineStatusType.Pendente) {
        await this.iniciarAtendimentoDoc();
      }
    }
  }

  async copiarDocParaTransf() {
    if (!this.selectedDoc) {
      await this.commonService.mensagem("Selecione o Documento", "Não há um Documento selecionado.", "warning");
      return;
    }

    this.storageService.setItem('app.solicClipboard', JSON.stringify(this.selectedDoc));

    this.snackBar.open('Documento copiado para área de transferência.', 'Ok', {
      duration: 3000
    });
  }

  prepareBadge(strBadge: string): string {
    // Non-breakable space is char 0xa0 (160 dec) (html &nbsp;)
    const strSpace = "\xa0";
    let result = strSpace + strBadge + strSpace;
    return result;
  }

  limparChatInput() {
    this.messageRows = 1;
    this.chatMessage = "";
    this.defineDesignInput();
  }

  quebraLinha() {
    if (this.messageRows >= 4) {
      return;
    }

    this.messageRows++;
    this.defineDesignInput();
  }

  defineDesignInput() {
    // debugger

    // let heightInput: string;
    // switch (this.messageRows) {
    //   case 2:
    //     heightInput = '90px';
    //     break;
    //   case 3:
    //     heightInput = '120px';
    //     break;
    //   case 4:
    //     heightInput = '150px';
    //     break;
    //   default:
    //     heightInput = '60px';
    //     break;
    // }

    // let cssRoot = document.querySelector(':root');
    // let cssRootStyle = getComputedStyle(cssRoot);
    // cssRootStyle.setProperty('--chatInputHeight', heightInput);
  }

  //#region Envio arquivos
  async whatsFileUploadResponse(retorno: string) {
    if (retorno === 'enviado') {
      await this.getMsgChat(this.selectedChat.id);
      this.commonService.mensagem("Arquivo enviado", "", "success");
    } else if (retorno === 'cancelado') {
      this.commonService.mensagem("Nenhum arquivo foi enviado", "", "info");
    } else {
      this.commonService.mensagem("ERRO: Arquivo não enviado", retorno, "error");
    }
  }
  //#endregion

  //#region Métodos Gerais
  calcularBolotinhas() {
    let listaFiltradaAtendente = this.listaChatAtendente.filter(x => x.qtdMsgNaoVista > 0);
    this.qtdBolotinhasAtendente = listaFiltradaAtendente.length;

    let listaFiltradaBot = this.listaChatBot.filter(x => x.qtdMsgNaoVista > 0);
    this.qtdBolotinhasBot = listaFiltradaBot.length;
  }
  //#endregion
}