import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as moment from 'moment';
import { Label } from 'ng2-charts';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { CommonService, UsuarioService } from 'projects/ProjetoBaseAngular/domain/services';
import { PrintService } from 'projects/ProjetoBaseAngular/domain/services/print.service';
import { RelatorioService } from '../domain/services';
import { TypesService } from 'projects/admin/src/domain/services';
import { BotAtendimentoType, BotFluxoType, SolicitacaoOnlineStatusType, WhatsChatStatusType, WhatsChatType } from '../../atendimento-online/domain/types';

@Component({
  selector: 'app-rel-atendimentos-chatbot',
  templateUrl: './rel-atendimentos-chatbot.component.html'
})
export class RelAtendimentosChatbotComponent extends BaseComponent implements OnInit {

  isBusy: boolean;

  titulo = 'Relatório Atendimentos Chatbot';
  tituloModelo: string;
  dataAtual: string;
  dataInicial: string;
  dataFinal: string;
  statusChat: WhatsChatStatusType;
  statusSolicitacoes: SolicitacaoOnlineStatusType;
  tipoRelatorio: number;
  listaDto: any[] = [];
  listaApresentacao: any[] = [];

  
  qtdRegistros: number;
  listaUsuarioDto: any[] = [];
  
  showGraficoChatsByAtendente: boolean = false;
  chartChatsByAtendenteLabels: Label[] = [];
  chartChatsByAtendenteData: ChartDataSets[] = [];
  chartChatsByAtendenteType: ChartType = 'pie';
  chartChatsByAtendenteLegend = true;
  chartChatsByAtendentePlugins = [];
  chartChatsByAtendenteOptions: ChartOptions = {
    responsive: true,
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          return this.getTooltipLabelChatsByAtendente(tooltipItem, data);
        }
      }
    },
  };

  showGraficoChatByFluxo: boolean = false;
  chartChatByFluxoLabels: Label[] = [];
  chartChatByFluxoData: ChartDataSets[] = [];
  chartChatByFluxoType: ChartType = 'bar';
  chartChatByFluxoLegend = true;
  chartChatByFluxoPlugins = []; //[pluginDataLabels];
  chartChatByFluxoOptions: ChartOptions = {
    responsive: true,
    tooltips: {
      callbacks: {
        label: (tooltipItem) => {
          return this.getTooltipLabelChatByFluxo(tooltipItem);
        }
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }],
      xAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };

  showGraficoChatByTipoAtendimento: boolean = false;
  chartChatByTipoAtendimentoLabels: Label[] = [];
  chartChatByTipoAtendimentoData: ChartDataSets[] = [];
  chartChatByTipoAtendimentoType: ChartType = 'bar';
  chartChatByTipoAtendimentoLegend = true;
  chartChatByTipoAtendimentoPlugins = []; //[pluginDataLabels];
  chartChatByTipoAtendimentoOptions: ChartOptions = {
    responsive: true,
    tooltips: {
      callbacks: {
        label: (tooltipItem) => {
          return this.getTooltipLabelChatByTipoAtendimento(tooltipItem);
        }
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }],
      xAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };

  //#region Solicitações
  listaDtoSolicitacoes: any[] = [];
  listaApresentacaoSolicitacoes: any[] = []; 
  qtdRegistrosSolicitacoes: number;

  showGraficoSolicitacoesByAtendente: boolean = false;
  chartSolicitacoesByAtendenteLabels: Label[] = [];
  chartSolicitacoesByAtendenteData: ChartDataSets[] = [];
  chartSolicitacoesByAtendenteType: ChartType = 'pie';
  chartSolicitacoesByAtendenteLegend = true;
  chartSolicitacoesByAtendentePlugins = [];
  chartSolicitacoesByAtendenteOptions: ChartOptions = {
    responsive: true,
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          return this.getTooltipLabelSolicitacoesByAtendente(tooltipItem, data);
        }
      }
    },
  };  
  //#endregion

  constructor(
    injector: Injector,
    readonly service: RelatorioService,
    readonly printService: PrintService,
    readonly commonService: CommonService,
    readonly typesService: TypesService,
    readonly usuarioService: UsuarioService,
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();

    if (!this.isAllowed()) {
      this.commonService.mensagem("", "Você não tem permissão de execução de relatórios!", "info");
    }

    this.dataAtual = moment().format('DD/MM/YYYY');
    this.dataInicial = this.fromJsonDate(new Date());
    this.dataFinal = this.fromJsonDate(new Date());
    this.statusChat = WhatsChatStatusType.Finalizado;
    this.statusSolicitacoes = SolicitacaoOnlineStatusType.Pendente_EmAtendimento;
    this.tipoRelatorio = 1;
  }

  isAllowed(): boolean {
    return super.isAllowed("Read", "WhatsAppDashboard");
  }

  getFilterDto(): any {
    const filterDto = {
      dataInicial: this.dataInicial,
      dataFinal: this.dataFinal,
      status: this.statusChat,
    }
    return filterDto;
  }

  getFilterDocDto(): any {
    const filterDto = {
      dataInicial: this.dataInicial,
      dataFinal: this.dataFinal,
      status: this.statusSolicitacoes,
    }
    return filterDto;
  }

  async print() {
    //Limpar o HTML
    this.listaDto = [];
    this.listaDtoSolicitacoes = [];
    this.listaApresentacao = [];
    this.listaApresentacaoSolicitacoes = [];

    if (!this.commonService.validarDataInput(this.dataInicial)) {
      this.commonService.mensagem("Atenção", "Digite uma data inicial válida");
      return;
    }
    if (!this.commonService.validarDataInput(this.dataFinal)) {
      this.commonService.mensagem("Atenção", "Digite uma data final válida");
      return;
    }

    this.commonService.spinnerOpen("Filtrando Relatório...");
    this.isBusy = true;

    this.listaDto = await this.service.getAtendimentosPeriodo(this.getFilterDto()).toPromise();
    this.listaDtoSolicitacoes = await this.service.getSolicitacoesByPeriodo(this.getFilterDocDto()).toPromise();

    if (this.listaDto.length > 0) {
      this.qtdRegistros = this.listaDto.length;      
      this.listaUsuarioDto = await this.usuarioService.getSelectList().toPromise();
      console.log(this.listaUsuarioDto);

      this.tratarListaAtendimentos();      
      this.initChartChatsByAtendente();
      this.initChartChatByFluxo();
      this.initChartChatByTipoAtendimento();

      this.commonService.spinnerClose();
      this.isBusy = false;
    } else {
      this.commonService.spinnerClose();
      this.isBusy = false;
      this.commonService.mensagem("Não foram encontrados dados de Chats para este filtro", "", "info");
    }

    if(this.listaDtoSolicitacoes.length > 0) {
      this.qtdRegistrosSolicitacoes = this.listaDtoSolicitacoes.length;
      this.tratarListaAtendimentosSolicitacoes();
      this.initChartSolicitacoesByAtendente();

    } else {
      this.commonService.mensagem("Não foram encontrados dados de Solicitações neste intervalo de datas", "", "info");
    }
  }

  //#region Graficos Geral
  async initChartChatsByAtendente() {
    //separar por tipo
    let dadosChatsByAtendente: number[] = [];
    this.chartChatsByAtendenteLabels = [];
    let chartChatsByAtendenteColors: any[] = [];

    this.listaApresentacao.forEach(x => {
      if (x.nome === "Bot") {
        chartChatsByAtendenteColors.push("#2196f3");
      } else {
        chartChatsByAtendenteColors.push(this.getRandomColor());
      }
      this.chartChatsByAtendenteLabels.push(x.nome);
      dadosChatsByAtendente.push(x.qtd);
    });

    this.chartChatsByAtendenteData = [
      {
        label: 'Processos por Atendente',
        data: dadosChatsByAtendente,
        backgroundColor: chartChatsByAtendenteColors
      },
    ];

    this.showGraficoChatsByAtendente = true;
  }

  async initChartChatByFluxo() {
    let dadosChartChatByFluxo: number[] = [];
    this.chartChatByFluxoLabels = [];
    let chartChatByFluxoColors: any[] = [];

    // Criando objeto com a quantidade de processos
    let qtdIniciadoAtendente = this.listaDto.filter(x => x.tipoFluxo === BotFluxoType.NaoInformado).length;
    let qtdIniciadoCliente = this.listaDto.filter(x => x.tipoFluxo === BotFluxoType.IniciadoCliente).length;
    let qtdConfirmacaoPresenca = this.listaDto.filter(x => x.tipoFluxo === BotFluxoType.ConfirmacaoPresenca).length;
    let qtdPesquisaSatisfacao = this.listaDto.filter(x => x.tipoFluxo === BotFluxoType.PesquisaSatisfacao).length;
    let qtdAceiteTermo = this.listaDto.filter(x => x.tipoFluxo === BotFluxoType.AceiteTermo).length;

    //Aplicar dados
    this.chartChatByFluxoLabels.push('Iniciado por Atendente');
    this.chartChatByFluxoLabels.push('Iniciado por Cliente');
    this.chartChatByFluxoLabels.push('Confirmação de Presença');
    this.chartChatByFluxoLabels.push('Pesquisa de Satisfação');
    this.chartChatByFluxoLabels.push('Aceite de Termo');

    dadosChartChatByFluxo.push(qtdIniciadoAtendente);
    dadosChartChatByFluxo.push(qtdIniciadoCliente);
    dadosChartChatByFluxo.push(qtdConfirmacaoPresenca);
    dadosChartChatByFluxo.push(qtdPesquisaSatisfacao);
    dadosChartChatByFluxo.push(qtdAceiteTermo);

    chartChatByFluxoColors.push(this.typesService.getBotFluxoTypeColorHex(BotFluxoType.NaoInformado));
    chartChatByFluxoColors.push(this.typesService.getBotFluxoTypeColorHex(BotFluxoType.IniciadoCliente));
    chartChatByFluxoColors.push(this.typesService.getBotFluxoTypeColorHex(BotFluxoType.ConfirmacaoPresenca));
    chartChatByFluxoColors.push(this.typesService.getBotFluxoTypeColorHex(BotFluxoType.PesquisaSatisfacao));
    chartChatByFluxoColors.push(this.typesService.getBotFluxoTypeColorHex(BotFluxoType.AceiteTermo));

    this.chartChatByFluxoData = [
      { data: dadosChartChatByFluxo, label: 'Chats por Fluxo', backgroundColor: chartChatByFluxoColors, borderColor: '#ffffff', hoverBackgroundColor: chartChatByFluxoColors, borderWidth: 1 },
    ];

    this.showGraficoChatByFluxo = true;
  }

  async initChartChatByTipoAtendimento() {
    let dadosChartChatByTipoAtendimento: number[] = [];
    this.chartChatByTipoAtendimentoLabels = [];
    let chartChatByTipoAtendimentoColors: any[] = [];

    console.log(this.listaDto);
    // Criando objeto com a quantidade de processos
    let qtdNaoInformado = this.listaDto.filter(x => x.tipoAtendimento === BotAtendimentoType.NaoInformado).length;
    let qtdConsultaRetorno = this.listaDto.filter(x => x.tipoAtendimento === BotAtendimentoType.ConsultaRetorno).length;
    let qtdExames = this.listaDto.filter(x => x.tipoAtendimento === BotAtendimentoType.Exames).length;
    let qtdReceitas = this.listaDto.filter(x => x.tipoAtendimento === BotAtendimentoType.Receitas).length;
    let qtdAtestados = this.listaDto.filter(x => x.tipoAtendimento === BotAtendimentoType.Atestados).length;
    let qtdAtendente = this.listaDto.filter(x => x.tipoAtendimento === BotAtendimentoType.Atendente).length;

    //Aplicar dados
    this.chartChatByTipoAtendimentoLabels.push('Não gera Doc.');
    this.chartChatByTipoAtendimentoLabels.push('Consulta Retorno');
    this.chartChatByTipoAtendimentoLabels.push('Exames');
    this.chartChatByTipoAtendimentoLabels.push('Receitas');
    this.chartChatByTipoAtendimentoLabels.push('Atestados');
    this.chartChatByTipoAtendimentoLabels.push('Repasse p. Atendente');

    dadosChartChatByTipoAtendimento.push(qtdNaoInformado);
    dadosChartChatByTipoAtendimento.push(qtdConsultaRetorno);
    dadosChartChatByTipoAtendimento.push(qtdExames);
    dadosChartChatByTipoAtendimento.push(qtdReceitas);
    dadosChartChatByTipoAtendimento.push(qtdAtestados);
    dadosChartChatByTipoAtendimento.push(qtdAtendente);

    chartChatByTipoAtendimentoColors.push(this.typesService.getBotAtendimentoTypeColorHex(BotAtendimentoType.NaoInformado));
    chartChatByTipoAtendimentoColors.push(this.typesService.getBotAtendimentoTypeColorHex(BotAtendimentoType.ConsultaRetorno));
    chartChatByTipoAtendimentoColors.push(this.typesService.getBotAtendimentoTypeColorHex(BotAtendimentoType.Exames));
    chartChatByTipoAtendimentoColors.push(this.typesService.getBotAtendimentoTypeColorHex(BotAtendimentoType.Receitas));
    chartChatByTipoAtendimentoColors.push(this.typesService.getBotAtendimentoTypeColorHex(BotAtendimentoType.Atestados));
    chartChatByTipoAtendimentoColors.push(this.typesService.getBotAtendimentoTypeColorHex(BotAtendimentoType.Atendente));

    this.chartChatByTipoAtendimentoData = [
      { data: dadosChartChatByTipoAtendimento, label: 'Chats por Tipo Atendimento', backgroundColor: chartChatByTipoAtendimentoColors, borderColor: '#ffffff', hoverBackgroundColor: chartChatByTipoAtendimentoColors, borderWidth: 1 },
    ];

    this.showGraficoChatByTipoAtendimento = true;
  }

  async initChartSolicitacoesByAtendente() {
    //separar por tipo
    let dadosSolicitacoesByAtendente: number[] = [];
    this.chartSolicitacoesByAtendenteLabels = [];
    let chartSolicitacoesByAtendenteColors: any[] = [];

    this.listaApresentacaoSolicitacoes.forEach(x => {
      if (x.nome === "Bot") {
        chartSolicitacoesByAtendenteColors.push("#2196f3");
      } else {
        chartSolicitacoesByAtendenteColors.push(this.getRandomColor());
      }
      this.chartSolicitacoesByAtendenteLabels.push(x.nome);
      dadosSolicitacoesByAtendente.push(x.qtd);
    });

    this.chartSolicitacoesByAtendenteData = [
      {
        label: 'Processos por Atendente',
        data: dadosSolicitacoesByAtendente,
        backgroundColor: chartSolicitacoesByAtendenteColors
      },
    ];

    this.showGraficoSolicitacoesByAtendente = true;
  }
  //#endregion

  //#region metodos gerais
  // Função para gerar cores aleatórias
  getUsuarioNome(usuarioId: string): string {
    return this.listaUsuarioDto.find(x => x.value === usuarioId)?.text;
  }

  getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  tratarListaAtendimentos() {
    let listaAgrupada = this.commonService.groupBy(this.listaDto, 'usuarioId');

    for (const key in listaAgrupada) {
      const element = listaAgrupada[key];
      if (key === "") {
        this.listaApresentacao.push({
          nome: "Bot",
          qtd: element.length,
          percentual: ((element.length / this.qtdRegistros) * 100).toFixed(2)
        });
      } else {
        this.listaApresentacao.push({
          nome: this.getUsuarioNome(key),
          qtd: element.length,
          percentual: ((element.length / this.qtdRegistros) * 100).toFixed(2)
        });
      }
    }
  }

  tratarListaAtendimentosSolicitacoes() {
    let listaAgrupada = this.commonService.groupBy(this.listaDtoSolicitacoes, 'usuarioId');

    for (const key in listaAgrupada) {
      const element = listaAgrupada[key];
      if (key === "") {
        this.listaApresentacaoSolicitacoes.push({
          nome: "Bot",
          qtd: element.length,
          percentual: ((element.length / this.qtdRegistrosSolicitacoes) * 100).toFixed(2)
        });
      } else {
        this.listaApresentacaoSolicitacoes.push({
          nome: this.getUsuarioNome(key),
          qtd: element.length,
          percentual: ((element.length / this.qtdRegistrosSolicitacoes) * 100).toFixed(2)
        });
      }
    }
  }
  //#endregion

  //#region Tooltip
  getTooltipLabelChatsByAtendente(tooltipItem: any, data: any): string {
    let qtd: number = data.datasets[0].data[tooltipItem.index];
    let percentual: number = Math.round((qtd * 100 / this.qtdRegistros) * 100) / 100;
    let nome: string = data.labels[tooltipItem.index];

    return `${nome}: ${qtd} | ${percentual} %`;
  }

  getTooltipLabelChatByFluxo(tooltipItem: any): string {
    let valor = tooltipItem.yLabel;

    return `${tooltipItem.label}: ${valor}`;
  }
  
  getTooltipLabelChatByTipoAtendimento(tooltipItem: any): string {
    let valor = tooltipItem.yLabel;

    return `${tooltipItem.label}: ${valor}`;
  }

  getTooltipLabelSolicitacoesByAtendente(tooltipItem: any, data: any): string {
    let qtd: number = data.datasets[0].data[tooltipItem.index];
    let percentual: number = Math.round((qtd * 100 / this.qtdRegistrosSolicitacoes) * 100) / 100;
    let nome: string = data.labels[tooltipItem.index];

    return `${nome}: ${qtd} | ${percentual} %`;
  }
  //#endregion

}
