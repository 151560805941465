<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true"
  [config]="{ignoreBackdropClick: true, keyboard: false}" (onShown)="onShown()">
  <div class="modal-dialog modal-lg">
    <mat-progress-bar *ngIf="isBusy" mode="indeterminate"></mat-progress-bar>
    <div class="card modal-content" *ngIf="form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="header">
          <h2>Arquivo do Paciente</h2>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="errors.length > 0">
            <h3>Oops! Algo deu errado:</h3>
            <ul>
              <li *ngFor="let error of errors"></li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-6">
              <mat-form-field>
                <mat-label>Nome</mat-label>
                <input matInput formControlName="nome" type="text" />
                <mat-error>Informe um Nome válido.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-sm-3">
              <button mat-flat-button color="primary" class="button-row" type="button" (click)="fileUpload.click()">
                Upload Imagem
              </button>
              <input id="inputUpItemfile" #fileUpload class="form-control-file" type="file"
                (change)="upload($event)" style="display: none;" />              
            </div>
            <div class="col-md-3">
              <button mat-flat-button color="primary" class="button-row" type="button"
                (click)="showLinq(form.value.arquivoUrl)">Abrir Arquivo</button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>URL Arquivo</mat-label>
                <input matInput type="url" disabled value="{{form.value.arquivoUrl}}" />
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
      <div class="footer">
        <mat-slide-toggle [(ngModel)]="multiCreate" color="primary" *ngIf="newItem" style="margin-right: 10px;">
          Continuar Incluindo
        </mat-slide-toggle>
        <button mat-flat-button [disabled]="isBusy" type="submit" (click)="save()" color="primary" class="button-row">
          <span> Salvar</span>
        </button>
        <button mat-stroked-button type="button" [routerLink]="" (click)="close()" class="button-row">
          <span> Cancelar</span>
        </button>
      </div>
    </div>
  </div>
</div>