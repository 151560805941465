import { Component, OnInit, ViewChild, Injector, ElementRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Validators } from '@angular/forms';

import { BaseCrudModalComponent } from 'projects/ProjetoBaseAngular/app/base-crud-modal.component';
import { PacienteArquivoService, PacienteService } from '../../../domain/services';
import { PacienteArquivoModel } from '../../../domain/models';
import { ArquivoService } from 'projects/ProjetoBaseAngular/domain/services';

@Component({
  selector: 'app-paciente-arquivo-modal',
  templateUrl: './paciente-arquivo-modal.component.html'
})
export class PacienteArquivoModalComponent extends BaseCrudModalComponent<PacienteArquivoModel> implements OnInit {
  @ViewChild('modal') modal: ModalDirective;

  file: any = {};
  localUrl: any;

  constructor(
    injector: Injector,
    service: PacienteArquivoService,
    readonly arquivoService: ArquivoService,
    readonly pacienteService: PacienteService
  ) {
    super(injector, service);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected initializeForm(model: PacienteArquivoModel) {
    this.form = this.formBuilder.group({
      id: model.id,
      dataInclusao: [model.dataInclusao, Validators.required],
      dataAlteracao: model.dataAlteracao,

      pacienteId: [model.pacienteId, Validators.required],
      nome: [model.nome, [
        Validators.required, Validators.maxLength(50)
      ]],
      arquivoUrl: [model.arquivoUrl],
      arquivoUrlBase: [model.arquivoUrlBase],
      arquivoPath: [model.arquivoPath],

      arquivoBase64: [model.arquivoBase64],
      extensaoBase64: [model.extensaoBase64]
    });
  }

  getLocalFile() {
    document.getElementById("inputUpfile").click();
  }

  async upload(event: any) {
    // Necessário para o upload via base64
    debugger

    this.file = <File>event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
      }
      reader.readAsDataURL(event.target.files[0]);
    }

    const model: PacienteArquivoModel = Object.assign({}, new PacienteArquivoModel(), this.form.value);

    let confirmacao: boolean;
    if (model.arquivoUrl) {
      confirmacao = await this.commonService.mensagemConfirmacao("Upload", "Confirmar upload de novo arquivo sobrescrevendo o atual?", "question");
    } else {
      confirmacao = await this.commonService.mensagemConfirmacao("Upload", "Confirmar upload de arquivo?", "question");
    }

    if (confirmacao) {
      let nomeArquivo: string = model.id;

      let descricaoArquivo: string;
      if (model.nome) {
        descricaoArquivo = `${model.nome.substring(0, 10)}`;
      } else {
        descricaoArquivo = `${this.file.name.substring(0, 10)}`;
      }

      this.file.process = true;
      this.file.completed = false;
      const formData = new FormData();
      formData.append('file', this.file);

      this.arquivoService.upload(model.id, nomeArquivo, descricaoArquivo, formData).subscribe(
        (fileInfo) => {
          this.file.inProgress = false;
          this.file.completed = true;
          this.form.patchValue({
            arquivoUrlBase: fileInfo.urlBase,
            arquivoPath: fileInfo.path,
            arquivoUrl: `${fileInfo.urlBase}${fileInfo.path}`
          });
        },
        (errors) => {
          this.file.inProgress = false;
          this.file.completed = false;
          this.errors = errors;
        });
    }
  }

  async save() {
    this.errors = [];
    if (!this.form.valid) {
      this.errors.push('Verifique se todos os campos obrigatórios foram preenchidos!');
      return;
    } else {
      super.save();
    }
  }

  showLinq(url: string) {
    window.open(url, "_blank");
  }
}
