<div class="main-container">
  <div class="left-container">
    <div class="row">
      <div class="header">
        <h4>Chats - Atendentes</h4>
      </div>
    </div>

    <div class="row">
      <div class="chatbutton-container" style="padding-left: 0px; padding-right: 0px; text-align: center;">
        <div class="col-md-12" style="padding-left: 1px; padding-right: 1px;">
          <button mat-flat-button class="chatbutton" type="button" matTooltip="Opções de filtro de chats"
            (click)="showChatFiltro()" style="padding: 0;">
            <span class="material-icons">filter_list</span>
          </button>
          <button mat-flat-button class="chatbutton" type="button" matTooltip="Finalizar o chat selecionado"
            (click)="finalizarChat()" style="padding: 0;">
            <span class="material-icons">check_circle_outline</span>
          </button>
          <button mat-flat-button class="chatbutton" type="button" matTooltip="Cancelar o Chat selecionado"
            (click)="cancelarChat()" style="padding: 0;">
            <span class="material-icons">highlight_off</span>
          </button>
          <button mat-flat-button class="chatbutton" type="button"
            matTooltip="Iniciar novo Chat com Paciente selecionado" (click)="initChatPaciente()" style="padding: 0;">
            <span class="material-icons">person_search</span>
          </button>
          <button mat-flat-button class="chatbutton" type="button"
            matTooltip="Iniciar novo Chat indicando um número de contato" (click)="initChatNaoPaciente()"
            style="padding: 0;">
            <span class="material-icons">pin</span>
          </button>
          <button mat-flat-button class="chatbutton" type="button"
            matTooltip="Transferir atendimento do Chat para o usuário" (click)="tranferirChat()" style="padding: 0;">
            <span class="material-icons">start</span>
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <ul class="nav nav-tabs">
        <li class="active"><a class="nav-link active" id="nav-tab-atendente-tab" data-toggle="tab"
            href="#nav-tab-atendente" role="tab" aria-controls="nav-tab-atendente" aria-selected="true"
            (click)="filtrarChat()">
            <span style="display: flex; align-items: center;">
              Atendente
              <b class="bolotinha" *ngIf="qtdBolotinhasAtendente > 0">{{qtdBolotinhasAtendente}}</b>
            </span>
          </a>
        </li>
        <li>
          <a class="nav-link" id="nav-tab-bot-tab" data-toggle="tab" href="#nav-tab-bot" role="tab"
            aria-controls="nav-tab-bot" aria-selected="false" (click)="filtrarChat()">
            <span style="display: flex; align-items: center;">
              Bot
              <b class="bolotinha" *ngIf="qtdBolotinhasBot > 0">{{qtdBolotinhasBot}}</b>
            </span>
          </a>
        </li>
      </ul>
    </div>

    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade in active" id="nav-tab-atendente" role="tabpanel"
        aria-labelledby="nav-tab-atendente-tab">
        <!--chats -->
        <div class="chat-list" *ngIf="listaChatAtendente.length > 0">
          <div class="chat-box" *ngFor="let chat of listaChatAtendente" [ngClass]="chat.selected"
            (click)="selectChat(chat.id)">
            <div class="img-box">
              <img class="img-cover" src={{chat.profileImgUrl}} alt="">
            </div>
            <div class="chat-details">
              <div class="text-head">
                <h4>{{chat.nomeContato}}</h4>
                <p class="time unread">{{chat.ultimaIteracao | date:'dd/MM/yyyy HH:mm'}}</p>
              </div>
              <div class="text-message">
                <p>{{chat.ultimaMsgRecebida}}</p>
                <b *ngIf="chat.qtdMsgNaoVista > 0">{{chat.qtdMsgNaoVista}}</b>
              </div>
              <div class="text-footer">
                <p [ngClass]="typesService.getWhatsChatTypeClass(chat.tipoChat)">{{prepareBadge(chat.nomeUsuario)}}</p>
                <p class="paciente" *ngIf="chat.pacienteId">{{prepareBadge("Paciente")}}</p>
                <p class="nao-paciente" *ngIf="!chat.pacienteId">{{prepareBadge("Não Paciente")}}</p>
                <p [ngClass]="typesService.getWhatsChatStatusTypeClass(chat.status)">
                  {{prepareBadge(typesService.getWhatsChatStatusTypeNome(chat.status))}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-pane fade" id="nav-tab-bot" role="tabpanel" aria-labelledby="nav-tab-bot-tab">
        <div class="chat-list" *ngIf="listaChatBot.length > 0">
          <div class="chat-box" *ngFor="let chat of listaChatBot" [ngClass]="chat.selected"
            (click)="selectChat(chat.id)">
            <div class="img-box">
              <img class="img-cover" src={{chat.profileImgUrl}} alt="">
            </div>
            <div class="chat-details">
              <div class="text-head">
                <h4>{{chat.nomeContato}}</h4>
                <p class="time unread">{{chat.ultimaIteracao | date:'dd/MM/yyyy HH:mm'}}</p>
              </div>
              <div class="text-message">
                <p>{{chat.ultimaMsgRecebida}}</p>
                <b *ngIf="chat.qtdMsgNaoVista > 0">{{chat.qtdMsgNaoVista}}</b>
              </div>
              <div class="text-footer">
                <p [ngClass]="typesService.getWhatsChatTypeClass(chat.tipoChat)">{{prepareBadge(chat.nomeUsuario)}}
                </p>
                <p class="paciente" *ngIf="chat.pacienteId">{{prepareBadge("Paciente")}}</p>
                <p class="nao-paciente" *ngIf="!chat.pacienteId">{{prepareBadge("Não Paciente")}}</p>
                <p [ngClass]="typesService.getWhatsChatStatusTypeClass(chat.status)">
                  {{prepareBadge(typesService.getWhatsChatStatusTypeNome(chat.status))}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="midle-container">
    <!--header -->
    <div class="header">
      <div class="img-text" *ngIf="selectedChat">
        <div class="user-img">
          <img class="dp" src={{selectedChat.profileImgUrl}} alt="">
        </div>
        <h4>{{selectedChat.nomeContato}}<br><span>{{selectedChat.numeroWhatsApp}}</span></h4>
      </div>
    </div>

    <!--chat-container -->
    <div class="chat-container">
      <div *ngIf="listaMsg.length > 0">
        <div class="message-box" *ngFor="let item of listaMsg"
          [ngClass]="item.tipo === whatsChatMsgType.Recebida ? 'friend-message' : 'my-message'">
          <p *ngIf="item.tipoMidia === whatsChatMsgMidiaType.Texto" [innerHTML]="item.mensagem"></p>

          <div *ngIf="item.tipoMidia === whatsChatMsgMidiaType.Imagem" (click)="imgClick(item.id)">
            <img *ngIf="item.media?.thumbnailUrl && !item.arquivoUrl" class="thumbnail-img"
              src={{item.media?.thumbnailUrl}} alt="">
            <img *ngIf="!item.media?.thumbnailUrl && item.arquivoUrl" class="thumbnail-img" src={{item.arquivoUrl}}
              alt="">
            <p [ngClass]="item.tipo === whatsChatMsgType.Recebida ? 'caption-received' : 'caption-send'"
              [innerHTML]="item.mensagem"></p>
          </div>
          <div *ngIf="item.tipoMidia === whatsChatMsgMidiaType.Documento">
            <p [ngClass]="item.tipo === whatsChatMsgType.Recebida ? 'caption-received' : 'caption-send'"
              [innerHTML]="item.mensagem"></p>
          </div>
        </div>
      </div>
    </div>

    <!--input-bottom -->
    <div class="chatbox-input">
      <button mat-flat-button class="chatbutton" type="button" style="margin: 10px;" (click)="anexarItem()"
        matTooltip="Anexar um PDF ou uma Imagem">
        <span class="material-icons">attach_file</span>
      </button>
      <textarea matInput [(ngModel)]="chatMessage" type="text" placeholder="Digite uma mensagem"
        (keyup.enter)="enviarMensgem()" [rows]="messageRows" (keydown.shift.enter)="quebraLinha()"></textarea>
      <button mat-flat-button class="chatbutton" type="button" style="margin: 10px;" (click)="enviarMensgem()"
        matTooltip="Enviar a mensagem">
        <span class="material-icons">send</span>
      </button>
    </div>
  </div>

  <div class="right-container">
    <div class="header">
      <h4>Documentos Gerados por ChatBot</h4>
    </div>

    <div class="row">
      <div class="docbutton-container">
        <div class="col-md-12" style="padding-left: 0px; padding-right: 0px; text-align: center;">
          <button mat-flat-button class="chatbutton" type="button" (click)="showDocFiltro()"
            matTooltip="Opções de filtro de chats" style="padding: 0;">
            <span class="material-icons">filter_list</span>
          </button>
          <button mat-flat-button class="chatbutton" type="button"
            matTooltip="Visualizar detalhes do documento selecionado" (click)="showDetalheDoc()" style="padding: 0;">
            <span class="material-icons">visibility</span>
          </button>
          <button mat-flat-button class="chatbutton" type="button" (click)="finalizarDoc()"
            matTooltip="Finalizar o documento selecionado" style="padding: 0;">
            <span class="material-icons">check_circle_outline</span>
          </button>
          <button mat-flat-button class="chatbutton" type="button" (click)="cancelarDoc()"
            matTooltip="Cancelar o documento selecionado" style="padding: 0;">
            <span class="material-icons">highlight_off</span>
          </button>
          <button mat-flat-button class="chatbutton" type="button" (click)="iniciarAtendimentoDoc()" style="padding: 0;"
            matTooltip="Iniciar resolução do documento selecionado">
            <span class="material-icons">update</span>
          </button>
          <button mat-flat-button class="chatbutton" type="button" (click)="iniciarChatDoc()" style="padding: 0;"
            matTooltip="Iniciar Chat para documento selecionado">
            <span class="material-icons">start</span>
          </button>
          <button mat-flat-button class="chatbutton" type="button" (click)="copiarDocParaTransf()" style="padding: 0;"
            matTooltip="Copiar documento para área de transferência">
            <span class="material-icons">content_copy</span>
          </button>
        </div>
      </div>
    </div>

    <div class="doc-list" *ngIf="listaSolicitacao.length > 0">
      <div class="doc-box" *ngFor="let item of listaSolicitacao" [ngClass]="item.selected" (click)="selectDoc(item.id)">
        <div class="status-box">
          <div class="status-label">
            <p [ngClass]="typesService.getSolicitacaoOnlineStatusTypeClass(item.status)">
              {{prepareBadge(typesService.getSolicitacaoOnlineStatusTypeNome(item.status))}}
            </p>
          </div>
          <p>{{item.dataInclusao | date:'dd/MM/yyyy'}}</p>
          <p>{{item.dataInclusao | date:'HH:mm'}}</p>
        </div>
        <div class="doc-details">
          <div class="text-head">
            <h4 [ngClass]="{'solicitacao-transf': item.tipo === botAtendimentoType.Atendente}">{{typesService.getBotAtendimentoTypeNome(item.tipo)}}</h4>
          </div>
          <div class="text-message">
            <p><strong>Profissional</strong><br>{{item.nomeMedico}}</p>
            <p><strong>Paciente</strong><br>{{item.nomePaciente}}</p>
            <p><strong>Fone</strong><br>{{item.numeroPaciente}}</p>
          </div>
          <div class="text-footer">
            <p class="com-responsavel" *ngIf="item.usuarioId">{{prepareBadge(item.nomeUsuario)}}</p>
            <p class="sem-responsavel" *ngIf="!item.usuarioId">{{prepareBadge(item.nomeUsuario)}}</p>
            <p class="paciente" *ngIf="item.pacienteId">{{prepareBadge("Paciente")}}</p>
            <p class="nao-paciente" *ngIf="!item.pacienteId">{{prepareBadge("Não Paciente")}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-docfiltro-modal #docfiltroModal (modalClose)="filtroDocResponse($event)"></app-docfiltro-modal>
<app-chatfiltro-modal #chatfiltroModal (modalClose)="filtroChatResponse($event)"></app-chatfiltro-modal>
<app-paciente-select-modal #pacienteSelect (modalClose)="onPacienteResponse($event)"></app-paciente-select-modal>
<app-bot-doc-modal #botDocModal></app-bot-doc-modal>
<app-imgview-modal #imgViewModal></app-imgview-modal>
<app-imagem-apresentacao-modal #imgApresentacaoModal></app-imagem-apresentacao-modal>
<app-whats-file-upload-modal #whatsFileUploadModal
  (modalClose)="whatsFileUploadResponse($event)"></app-whats-file-upload-modal>