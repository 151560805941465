import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { BaseComponent } from 'projects/ProjetoBaseAngular/app/base.component';
import { SelectDto } from 'projects/ProjetoBaseAngular/domain/models/select-dto';
import { PrintService } from 'projects/ProjetoBaseAngular/domain/services/print.service';
import { ProcedimentoService } from '../../atendimento/domain/services';
import { ProfissionalSaudeService } from '../../equipe/domain/services';
import { ConvenioService } from '../../parceiro/domain/services/convenio.service';
import { RelatorioService } from '../domain/services';

@Component({
  selector: 'app-rel-repasse-simplificado',
  templateUrl: './rel-repasse-simplificado.component.html'
})
export class RelRepasseSimplificadoComponent extends BaseComponent implements OnInit {
  @ViewChild('relatorio') relatorio: ElementRef;
  profissionais: SelectDto[];
  convenios: SelectDto[];
  procedimentos: SelectDto[];

  dataInicial: string;
  dataFinal: string;
  profissionalIds: string[] = [];
  convenioIds: string[] = [];
  procedimentoIds: string[] = [];

  listaDto: any[] = [];
  listaImpressao: any[] = [];

  totalGeral: number;
  totalClinica: number;
  totalMedico: number;
  qtdRegistros: number;

  isBusy: boolean;
  contentPrinted: boolean = false;

  constructor(
    injector: Injector,
    readonly service: RelatorioService,
    readonly profissionalSaudeService: ProfissionalSaudeService,
    readonly convenioService: ConvenioService,
    readonly procedimentoService: ProcedimentoService,
    readonly printService: PrintService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.isAllowed()) {
      this.commonService.mensagem("", "Você não tem permissão de execução de relatórios!", "info");
    }

    this.dataInicial = this.fromJsonDate(new Date());
    this.dataFinal = this.fromJsonDate(new Date());

    this.profissionalSaudeService.getSelectList().subscribe(x => this.profissionais = x);
    this.convenioService.getSelectList().subscribe(x => this.convenios = x);
    this.procedimentoService.getInternoSelectList().subscribe(x => this.procedimentos = x);
  }

  isAllowed(): boolean {
    return super.isAllowed("Read", "RelRepasseMedSimplificado");
  }

  async print() {
    //Para limar o Html
    this.listaImpressao = [];

    if (!this.commonService.validarDataInput(this.dataInicial)) {
      this.commonService.mensagem("Atenção", "Digite uma data inicial válida");
      return;
    }
    if (!this.commonService.validarDataInput(this.dataFinal)) {
      this.commonService.mensagem("Atenção", "Digite uma data final válida");
      return;
    }

    this.commonService.spinnerOpen("Filtrando Relatório...")
    this.isBusy = true;
    const filterDto = {
      dataInicial: this.dataInicial,
      dataFinal: this.dataFinal,
      profissionalIds: this.profissionalIds,
      convenioIds: this.convenioIds,
      procedimentoIds: this.procedimentoIds
    }

    this.listaDto = await this.service.getRepasse(filterDto).toPromise();

    if (this.listaDto.length > 0) {
      this.commonService.spinnerOpen("Preparando Impressão...");

      this.totalizadores();

      this.contentPrinted = false;
      this.preparaRelatorio();
    } else {
      this.commonService.spinnerClose();
      this.isBusy = false;
      this.commonService.mensagem("Não foram encontrados dados para esta seleção", "", "info");
    }
  }

  onContentPrinted() {
    this.contentPrinted = true;
    const content = this.relatorio.nativeElement.innerHTML;
    this.printService.print('Relatório de Repasse Médico', content);
    this.isBusy = false;
    this.commonService.spinnerClose();
  }

  totalizadores() {
    this.totalGeral = this.listaDto.map(x => x.vlrTotal).reduce((acumulador, atual) => {
      return acumulador + atual
    }, 0);
    this.totalClinica = this.listaDto.map(x => x.vlrClinica).reduce((acumulador, atual) => {
      return acumulador + atual
    }, 0);
    this.totalMedico = this.listaDto.map(x => x.vlrMedico).reduce((acumulador, atual) => {
      return acumulador + atual
    }, 0);
  }

  preparaRelatorio() {
    this.listaImpressao = [];
    let lista = this.commonService.groupBy(this.listaDto, "profissionalExecutanteNome");

    for (let prop in lista) {
      let obj = new Object();
      obj["nome"] = prop;
      obj["quantidade"] = lista[prop].length;
      obj["vlrTotal"] = lista[prop].map(x => x.vlrTotal).reduce((acumulador, atual) => {
        return acumulador + atual
      }, 0);
      obj["vlrClinica"] = lista[prop].map(x => x.vlrClinica).reduce((acumulador, atual) => {
        return acumulador + atual
      }, 0);
      obj["vlrMedico"] = lista[prop].map(x => x.vlrMedico).reduce((acumulador, atual) => {
        return acumulador + atual
      }, 0);
      obj["itens"] = [...lista[prop]];

      this.listaImpressao.push(obj);
    }
  }

  get dataAtual() {
    return moment().format('DD/MM/YYYY');
  }

  get profissionalRecursoNomes(): string {
    return this.profissionais?.filter(x => this.profissionalIds.includes(x.value)).map(x => x.text).join(', ') || 'Todos';
  }

  get convenioNomes(): string {
    return this.convenios?.filter(x => this.convenioIds.includes(x.value)).map(x => x.text).join(', ') || 'Todos';
  }

  get procedimentoNomes(): string {
    return this.procedimentos?.filter(x => this.procedimentoIds.includes(x.value)).map(x => x.text).join(', ') || 'Todos';
  }
}
