<div class="row clearfix">
  <div class="col-md-12">
    <div class="card">
      <div class="header">
        <h2>WhatsApp e ChatBot - Envio de confirmações de presença em Lote</h2>
      </div>
      <div class="body">
        <ul class="nav nav-tabs">
          <li class="active">
            <a class="nav-link active" id="nav-EnvioLote-tab" data-toggle="tab" href="#nav-EnvioLote" role="tab"
              aria-controls="nav-EnvioLote" aria-selected="true">Envio em Lote</a>
          </li>
          <li>
            <a class="nav-link" id="nav-fila-tab" data-toggle="tab" href="#nav-fila" role="tab" aria-controls="nav-fila"
              aria-selected="false">Fila Confirmação</a>
          </li>
        </ul>
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade in active" id="nav-EnvioLote" role="tabpanel" aria-labelledby="nav-EnvioLote-tab">
            <div class="row">
              <div class="col-md-3">
                <mat-form-field>
                  <mat-label>Data</mat-label>
                  <input #data matInput type="date" placeholder="Data" [(ngModel)]="dataExecutar" 
                    (blur)="onDataExit()">
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <button mat-flat-button type="button" (click)="filtrar()" color="primary" style="margin-left: 10px; margin-top: 25px;"
                  matTooltip="Filtrar agendamentos">
                  <mat-icon>refresh</mat-icon>
                  Filtrar Agendamentos
                </button>
                <button mat-flat-button type="button" (click)="executar()" color="primary" style="margin-left: 10px; margin-top: 25px;"
                  matTooltip="Executar envio de solicitação de confirmação">
                  <mat-icon>done_all</mat-icon>
                  Executar
                </button>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-2">
                <button mat-flat-button type="button" (click)="marcarReenvio()" class="btn-danger" style="margin-top: 25px;"
                  matTooltip="Reenviar - Seleciona os já enviados e refaz o processo de envio de solicitação de confirmação">
                  <mat-icon>task_alt</mat-icon>
                  Marcar p/ Reenviar
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table table-bordered table-striped table-hover dataTable"
                    style="width: 100%; max-height: 500px; overflow-y: scroll; display:inline-block;">
                    <thead>
                      <tr>
                        <th class="text-center">
                          <button mat-icon-button type="button" (click)="tbSelectAll()" class="button-row"
                            *ngIf="!totalSelect">
                            <mat-icon>check_box_outline_blank</mat-icon>
                          </button>
                          <button mat-icon-button type="button" (click)="tbDeSelectAll()" class="button-row"
                            *ngIf="totalSelect">
                            <mat-icon>check_box</mat-icon>
                          </button>
                        </th>
                        <th class="text-center" [width]="80">Hora</th>
                        <th class="text-center" [width]="150">Profissional/Recurso</th>
                        <th class="text-center" [width]="150">Classificação</th>
                        <th class="text-center" [width]="100">Status</th>
                        <th class="text-center" [width]="250">Paciente</th>
                        <th class="text-center" [width]="120">Fone</th>
                        <th class="text-center" [width]="120">Execução</th>
                        <th class="text-center" [width]="120">Data Exec.</th>
                        <th class="text-center">Motivo Falha</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="listaConfirmacao?.length != 0">
                      <tr *ngFor="let item of listaConfirmacao">
                        <td>
                          <button mat-icon-button type="button" (click)="tbSelect(item)" class="button-row"
                            *ngIf="!item.selecionado">
                            <mat-icon>check_box_outline_blank</mat-icon>
                          </button>
                          <button mat-icon-button type="button" (click)="tbDeSelect(item)" class="button-row"
                            *ngIf="item.selecionado">
                            <mat-icon>check_box</mat-icon>
                          </button>
                        </td>
                        <td>
                          {{item.horario | mask:'hh:MM'}}
                        </td>
                        <td>
                          {{item.profissionalRecursoNome}}
                        </td>
                        <td>
                          {{item.classificacaoNome}}
                        </td>
                        <td>
                          <span class="badge"
                            [style.backgroundColor]="typesService.getAgendamentoStatusColorType(item.status)">
                            {{typesService.getAgendamentoStatusType(item.status)}}
                          </span>
                        </td>
                        <td>
                          <span *ngIf="item.codigoPaciente">{{item.codigoPaciente}}-{{item.nomePaciente}}</span>
                          <span *ngIf="!item.codigoPaciente">Anotação: {{item.nomePaciente}}</span>
                        </td>
                        <td>
                          {{item.celularPaciente}}
                        </td>
                        <td>
                          <span class="label"
                            [ngClass]="typesService.getConfirmacaoLoteStatusTypeColor(item.statusExecucao)">
                            {{typesService.getConfirmacaoLoteStatusType(item.statusExecucao)}}
                          </span>
                        </td>
                        <td>
                          {{item.dataSolicConfirmacao | date:'dd/MM/yyyy HH:mm'}}
                        </td>
                        <td>
                          {{item.motivoFalha}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="nav-fila" role="tabpanel" aria-labelledby="nav-fila-tab">
            <div class="row">
              <div class="col-md-2">
                <mat-form-field>
                  <mat-label>Cód./CPF Paciente</mat-label>
                  <input matInput [value]="paciente?.pessoa?.cnpjCpf" type="text"
                    (blur)="pacienteSelect.changeDocumento($event.target.value)" maxlength="18" required />
                  <button matSuffix mat-icon-button type="button" (click)="pacienteSelect.showSelect()"
                    matTooltip="Selecionar Paciente">
                    <mat-icon>search</mat-icon>
                  </button>
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field>
                  <mat-label>Paciente</mat-label>
                  <input matInput disabled
                    [value]="(paciente?.pessoa?.codigo ? paciente?.pessoa?.codigo + ' - ' : '') + paciente?.pessoa?.nomeFantasia ? paciente?.pessoa?.nomeFantasia : ''"
                    type="text" />
                </mat-form-field>
              </div>
              <div class="col-md-2">
                <mat-form-field>
                  <mat-label>Número WhatsApp</mat-label>
                  <input matInput type="text" placeholder="Número WhatsApp" [(ngModel)]="filaNumeroWhats">
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field>
                  <mat-label>Status</mat-label>
                  <mat-select [(ngModel)]="filaStatus">
                    <mat-option *ngFor="let item of typesService.presencaFilaStatusType" [value]="item.value">
                      {{item.text}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-2">
                <button mat-flat-button type="button" (click)="filtrarFila()" color="primary" style="margin-left: 10px;"
                  matTooltip="Filtrar agendamentos">
                  <mat-icon>refresh</mat-icon>
                  Filtrar Fila
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table class="table table-bordered table-striped table-hover dataTable"
                    style="width: 100%; max-height: 500px; overflow-y: scroll; display:inline-block;">
                    <thead>
                      <tr>
                        <th class="text-center" [width]="200">Número WhatsApp
                        <th class="text-center" [width]="100">Data Inclusão</th>
                        <th class="text-center" [width]="100">Data Agend.</th>
                        <th class="text-center" [width]="100">Data Vencto</th>
                        <th class="text-center" [width]="100">Status</th>
                        <th class="text-center">Texto</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="listaFila?.length != 0">
                      <tr *ngFor="let item of listaFila">
                        <td>
                          {{item.numeroWhatsApp}}
                        </td>
                        <td>
                          {{item.dataInclusao | date: 'dd/MM/yyyy'}}
                        </td>
                        <td>
                          {{item.dataVencimento | date: 'dd/MM/yyyy HH:mm'}}
                        </td>
                        <td>
                          {{item.dataVencimento | date: 'dd/MM/yyyy'}}
                        </td>
                        <td>
                          <span class="badge"
                            [style.backgroundColor]="typesService.getPresencaFilaStatusTypeColor(item.status)">
                            {{typesService.getPresencaFilaStatusType(item.status)}}
                          </span>
                        </td>
                        <td>
                          {{item.texto}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <button mat-flat-button [disabled]="isBusy" type="button" (click)="back()" color="primary" class="button-row">
          <span> Voltar</span>
        </button>
      </div>
    </div>
  </div>
</div>

<app-paciente-select-modal #pacienteSelect (modalClose)="onPacienteResponse($event)"></app-paciente-select-modal>